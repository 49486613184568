import { render, staticRenderFns } from "./TrafficBody.vue?vue&type=template&id=0857fb69&scoped=true&"
import script from "./TrafficBody.vue?vue&type=script&lang=js&"
export * from "./TrafficBody.vue?vue&type=script&lang=js&"
import style0 from "./TrafficBody.vue?vue&type=style&index=0&lang=css&"
import style1 from "./TrafficBody.vue?vue&type=style&index=1&id=0857fb69&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0857fb69",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ctweb/fontend/kecc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0857fb69')) {
      api.createRecord('0857fb69', component.options)
    } else {
      api.reload('0857fb69', component.options)
    }
    module.hot.accept("./TrafficBody.vue?vue&type=template&id=0857fb69&scoped=true&", function () {
      api.rerender('0857fb69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DefaultHome/KECC/Visitors/Traffic/TrafficBody.vue"
export default component.exports