<template>
    <div class="traffic-container">
        <div class="container-fluid main wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <div class="row traffic-image-card-block">
                        <div class="col-lg-6 col-xs-12">
                            <p class="card-content mt-3" v-html="this.$t('sectionFirst.leftBody')">
                            </p>
                        </div>
                        <div class="col-lg-6 col-xs-12 pt-2">
                            <p class="card-content" v-html="$t('sectionFirst.rightBody_1')">

                            </p>
                            <p class="card-content" v-html="$t('sectionFirst.rightBody_2')">

                            </p>
                            <p class="card-content" v-html="$t('sectionFirst.rightBody_3')">

                            </p>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>

        <GoogleMap class="traffic-image" :mapConfig="mapConfig" apiKey="AIzaSyC1Hs5sAQzYMO7-vaPzMkeur8Kh3g5YCS4" />

        <div class="container-fluid parking wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-parking"></i>&nbsp;{{ $t('section_1.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_1.item_1.title') }}
                            </p>
                            <div v-html="$t('section_1.item_1.content')"></div>
                            <p class='card-content mt-15'><img src='~assets/project_images/pic/battery_icon.png' width='20' height='20' class="bettery_icon">{{ $t('section_1.item_1.content_2') }}</p>
                            <div v-html="$t('section_1.item_1.content_3')"></div>
                            <a target="_blank" href="https://www.dodohome.com.tw/" class="card-content-title mb-3" v-html=" $t('section_1.item_1.link_content2')"></a><br>
                            <a target="_blank" href="https://www.evalue.com.tw/" class="card-content-title mb-3" v-html=" $t('section_1.item_1.link_content')">
                            </a>
                        </div>
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_1.item_2.title') }}
                            </p>
                            <p class="card-content" v-html="$t('section_1.item_2.content')"></p>
                            <p class="card-content-title mb-3">
                                {{ $t('section_1.item_3.title') }}
                            </p>
                            <p class="card-content" v-html="$t('section_1.item_3.content')">

                            </p>
                        </div>
                    </div>

                    <div class="row pt-1">
                        <div class="col-6">
                            
                        </div>
                    </div>
                </b-card>
            </div>
        </div>


        <div class="container-fluid drive-a-car wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-car"></i>&nbsp;{{ $t('section_2.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_2.item_1.title') }}
                            </p>
                            <p class="card-content text-width" v-html="$t('section_2.item_1.content')">

                            </p>
                        </div>
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_2.item_2.title') }}
                            </p>
                            <p class="card-content text-width" v-html="$t('section_2.item_2.content')">

                            </p>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>

        <div class="container-fluid high-speed-rail wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-train"></i>&nbsp;{{ $t('section_3.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_3.item_1.title') }}
                            </p>
                            <p class="card-content">
                                {{ $t('section_3.item_1.content') }}
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_3.item_2.title') }}
                            </p>
                            <p class="card-content">
                                {{ $t('section_3.item_2.content') }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_3.item_3.title') }}
                            </p>
                            <p class="card-content">
                                {{ $t('section_3.item_3.content') }}
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <a target="_blank" href="https://www.thsrc.com.tw/" class="card-content-title mb-3" v-html="$t('section_3.item_4.title')">
                            </a>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>

        <div class="container-fluid mrt wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-subway"></i>&nbsp;{{ $t('section_4.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="bus-text" v-html="$t('section_4.item_3.title')">
                                
                            </h1>
                        </div>
                        <div class="col-lg-6">
                            <a target="_blank" href="https://www.krtc.com.tw/" class="card-content-title mb-3" v-html="$t('section_4.item_2.title')">
                            </a>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>

        <div class="container-fluid bus wow fadeInUp">
            <div class="row traffic-image-only-card">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-bus"></i>&nbsp;{{ $t('section_5.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="bus-text">
                                {{ $t('section_5.item_3.title') }}
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_5.item_1.title') }}
                            </p>
                            <div v-html="$t('section_5.item_1.content')"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="bus-text">
                                {{ $t('section_5.item_7.title') }}
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_5.item_4.title') }}
                            </p>
                            <div v-html="$t('section_5.item_4.content')"></div>
                        </div>
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_5.item_5.title') }}
                            </p>
                            <div v-html="$t('section_5.item_5.content')"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <a target="_blank" href="https://ibus.tbkc.gov.tw/ibus/" class="card-content-title mb-3" v-html="$t('section_5.item_6.title')">

                            </a>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>

        <div class="container-fluid light-rail wow fadeInUp">
            <div class="row traffic-image-only-card mb-last-block">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-subway"></i>&nbsp;{{ $t('section_6.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-12">
                            <h1 class="bus-text" v-html="$t('section_6.item_5.title')">
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_6.item_1.title') }}
                            </p>
                            <p class="card-content" v-html="$t('section_6.item_1.content')">
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <p class="card-content-title mb-3">
                                {{ $t('section_6.item_2.title') }}
                            </p>
                            <p class="card-content">
                                {{ $t('section_6.item_2.content') }}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <!-- <p class="card-content-title mb-3">
                                {{ $t('section_6.item_3.title') }}
                            </p>
                            <p class="card-content">
                                {{ $t('section_6.item_3.content') }}
                            </p> -->
                        </div>
                        <div class="col-lg-6">
                            <a target="_blank" href="https://www.krtc.com.tw/KLRT/" class="card-content-title mb-3" v-html="$t('section_6.item_4.title')">

                            </a>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
         <div class="container-fluid city-bike wow fadeInUp">
            <div class="row traffic-image-only-card mb-last-block">
                <b-card class="traffic-content-card">
                    <h1 class="mb-0">
                        <i class="fas fa-biking"></i>&nbsp;{{ $t('section_7.title') }}
                    </h1>
                    <hr size=1>
                    <div class="row">
                        <div class="col-lg-6">
                            <a target="_blank" href="https://www.youbike.com.tw/region/kcg/" class="card-content-title mb-3" v-html="$t('section_7.item_1.title')">
                            </a>                            
                            <p class="card-content" v-html="$t('section_7.item_1.content')">
                            </p>
                            <!-- <p class="card-content" v-html="$t('section_7.item_2.content')">
                            </p>                             -->
                        </div>
                        <div class="col-lg-6">
                        </div>
                    </div>
                </b-card>
            </div>
        </div>       
    </div>
</template>

<script>
    import GoogleMap from 'components/DefaultHome/KECC/Common/GoogleMap'
    export default {
        name: 'TrafficBody',
        components: {
            GoogleMap
        },
        data() {
            return {}
        },
        computed: {
            mapConfig() {
                return {
                    // ...mapSettings,
                    zoom: 14,
                    center: {
                        lat: 22.607874,
                        lng: 120.2983319
                    }
                }
            },
        },
    }
</script>

<style>
.card-content {
        font-size: 18px;
        /* padding-left: 14px; */
        font-weight: 500 !important;
        display: flex;
        gap: 5px;
    }
.card-content i{
        /* width: 20px; */
        margin-right: 5px;
        text-align: center;
        margin-top: 7px;
    }   
</style>
<style scoped>
    .main .traffic-image-only-card {
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .traffic-image-card-block {
        padding: 50px 30px;
    }
    .mt-first-block {
        margin-top: 15% !important;
    }

    .mb-last-block {
        margin-bottom: 100px !important;
    }

    .mg-b-50 {
        margin-bottom: 50px !important;
    }

    .traffic-position {
        position: relative;
    }

    .traffic-image {
        position: relative;
    }

    .traffic-image-small {
        width: 70%;
        position: relative;
        margin-left: 15%;
    }

    .traffic-image-small-card {
        width: 60% !important;
        background-color: #fff !important;
        padding: 0 30px;
        font-weight: bold;
    }

    .traffic-image-card {
        width: 50% !important;
        height: 200px;
        position: absolute;
        bottom: -15%;
        left: 25%;
        background-color: #fff !important;
    }

    .traffic-image-big-card {
        width: 70% !important;
        position: absolute;
        bottom: -28%;
        left: 15%;
        background-color: #fff !important;
        padding: 20px 100px;
    }

    .traffic-image-only-card {
        width: 70%;
        margin-top: 50px;
        margin-left: 15%;
        padding: 20px 100px;
        background-color: #fff !important;
    }

    .traffic-container .card {
        border: none;
    }

    .traffic-image-card,
    .traffic-image-small-card h2 {
        color: #000033;
    }

    .traffic-image-card,
    .traffic-image-small-card p {
        font-size: 18px;
    }

    .traffic-image-big-card p,
    .traffic-content-card p {
        font-size: 18px;
        font-weight: bold;
    }

    .traffic-image-only-card p {
        font-size: 18px;
        font-weight: bold;
    }

    .traffic-content-card {
        width: 100% !important;

        .indent-1 {
            margin-left: 29px;
        }
    }

    .traffic-content-card h1 {
        font-size: 50px;
        color: #000033;
    }


    .traffic-container i {
        color: #000033;
        margin-right: 10px;
    }

    .card-content-title {
        border: 1px solid #000033;
        color: #000033;
        padding: 6px 12px;
        display: inline-block;
        font-size: 18px !important;
        margin-top: 25px;
    }

    hr {
        background-color: rgba(239, 240, 239, 1) !important;
        height: 1px;
        border: none;
        margin-bottom: 0px;
    }

    .traffic-container a {
        font-size: 1.1rem;
        font-weight: bold;
        transition: all 0s;
    }

    .traffic-container a:hover {
        background-color: #000033;
        color: #ffffff;
    }

    .traffic-container a:hover i {
        color: #ffffff;
    }

    @media (max-width: 1200px) {
        .traffic-image-big-card {
            position: static;
            width: 100% !important;
            bottom: 0%;
            left: 0%;
            padding: 0 !important;
            background-color: #fff !important;
        }

        .traffic-position {
            position: static;
            margin-bottom: 0px;
        }

        .traffic-image-only-card {
            width: 100%;
            margin: 0;
            margin-top: 10%;
            padding: 0;
            background-color: #fff !important;
        }

        .traffic-image-small {
            width: 100%;
            position: static;
            margin-left: 0;
        }

        .traffic-image-small-card {
            width: 100% !important;
            position: static;
            padding: 0;
        }
    }

    .text-width {
        width: 80% !important;
    }

    .fa-external-link-alt {
        cursor: pointer !important;
    }

    .traffic-container .container-fluid {
        padding: 0 !important;
    }

    .bus-text {
        margin-top: 30px;
        font-size: 26px !important;
        color: #000033;
        padding: 0 !important;
    }
    .bettery_icon {
        margin-top: 5px;
        height: fit-content;
    }
    .mt-15 {
        margin-top: 15px;
    }

  
</style>