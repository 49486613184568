<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'visitors'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb"/>
        <div class="solutions-area section-width ptb-100">
            <div class="container">
                <div class="section-title">
                    <h2>{{ $t('navbar.menu_1_2') }}</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="category-navigate">
                        <ul>
                            <a href="#" @click.prevent="goAnchor('.parking')"><li class="btn btn-primary btn-lg">{{ $t('section_1.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.drive-a-car')"><li class="btn btn-primary btn-lg">{{ $t('section_2.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.high-speed-rail')"><li class="btn btn-primary btn-lg">{{ $t('section_3.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.mrt')"><li class="btn btn-primary btn-lg">{{ $t('section_4.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.bus')"><li class="btn btn-primary btn-lg">{{ $t('section_5.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.light-rail')"><li class="btn btn-primary btn-lg">{{ $t('section_6.title') }}</li></a>
                            <a href="#" @click.prevent="goAnchor('.city-bike')"><li class="btn btn-primary btn-lg">{{ $t('section_7.title') }}</li></a>
                        </ul>
                    </div>
                </div>
            </div>
        <TrafficBody />
        </div>
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import TrafficBody from './TrafficBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'
    import SmoothScroll from 'smooth-scroll'

    export default {
        name: 'TrafficMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_1_2"),
            }
        },
        components: {
            Navbar,
            Header,
            TrafficBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "TRAFFIC<br>INFORMATION",
                 borrowSpaceCrumb: [{
                    name : this.$t('navbar.menu_1')
                }]
            }
        },methods: {
            goAnchor(selector) {
                    const scroll = new SmoothScroll();
                    const anchor = document.querySelector(selector);
                    scroll.animateScroll(anchor, null, {
                        //updateURL: false,
                        speed: 800
                    });
            },
        }

    }
</script>

<style>
    .traffic-content-card{
        margin-top: 40px;
    }
</style>