import { render, staticRenderFns } from "./TrafficMain.vue?vue&type=template&id=bb4a3800&"
import script from "./TrafficMain.vue?vue&type=script&lang=js&"
export * from "./TrafficMain.vue?vue&type=script&lang=js&"
import style0 from "./TrafficMain.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ctweb/fontend/kecc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb4a3800')) {
      api.createRecord('bb4a3800', component.options)
    } else {
      api.reload('bb4a3800', component.options)
    }
    module.hot.accept("./TrafficMain.vue?vue&type=template&id=bb4a3800&", function () {
      api.rerender('bb4a3800', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DefaultHome/KECC/Visitors/Traffic/TrafficMain.vue"
export default component.exports