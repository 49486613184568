var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "traffic-container" },
    [
      _c("div", { staticClass: "container-fluid main wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("div", { staticClass: "row traffic-image-card-block" }, [
                _c("div", { staticClass: "col-lg-6 col-xs-12" }, [
                  _c("p", {
                    staticClass: "card-content mt-3",
                    domProps: {
                      innerHTML: _vm._s(this.$t("sectionFirst.leftBody"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6 col-xs-12 pt-2" }, [
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("sectionFirst.rightBody_1"))
                    }
                  }),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("sectionFirst.rightBody_2"))
                    }
                  }),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("sectionFirst.rightBody_3"))
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c("GoogleMap", {
        staticClass: "traffic-image",
        attrs: {
          mapConfig: _vm.mapConfig,
          apiKey: "AIzaSyC1Hs5sAQzYMO7-vaPzMkeur8Kh3g5YCS4"
        }
      }),
      _c("div", { staticClass: "container-fluid parking wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-parking" }),
                _vm._v(" " + _vm._s(_vm.$t("section_1.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_1.item_1.title")) + " ")
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_1.content"))
                    }
                  }),
                  _c("p", { staticClass: "card-content mt-15" }, [
                    _c("img", {
                      staticClass: "bettery_icon",
                      attrs: {
                        src: require("assets/project_images/pic/battery_icon.png"),
                        width: "20",
                        height: "20"
                      }
                    }),
                    _vm._v(_vm._s(_vm.$t("section_1.item_1.content_2")))
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_1.content_3"))
                    }
                  }),
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://www.dodohome.com.tw/"
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("section_1.item_1.link_content2")
                      )
                    }
                  }),
                  _c("br"),
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://www.evalue.com.tw/"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_1.link_content"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_1.item_2.title")) + " ")
                  ]),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_2.content"))
                    }
                  }),
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_1.item_3.title")) + " ")
                  ]),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_1.item_3.content"))
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row pt-1" }, [
                _c("div", { staticClass: "col-6" })
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container-fluid drive-a-car wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-car" }),
                _vm._v(" " + _vm._s(_vm.$t("section_2.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_2.item_1.title")) + " ")
                  ]),
                  _c("p", {
                    staticClass: "card-content text-width",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_1.content"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_2.item_2.title")) + " ")
                  ]),
                  _c("p", {
                    staticClass: "card-content text-width",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_2.item_2.content"))
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "container-fluid high-speed-rail wow fadeInUp" },
        [
          _c(
            "div",
            { staticClass: "row traffic-image-only-card" },
            [
              _c("b-card", { staticClass: "traffic-content-card" }, [
                _c("h1", { staticClass: "mb-0" }, [
                  _c("i", { staticClass: "fas fa-train" }),
                  _vm._v(" " + _vm._s(_vm.$t("section_3.title")) + " ")
                ]),
                _c("hr", { attrs: { size: "1" } }),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("p", { staticClass: "card-content-title mb-3" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_1.title")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "card-content" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_1.content")) + " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("p", { staticClass: "card-content-title mb-3" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_2.title")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "card-content" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_2.content")) + " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("p", { staticClass: "card-content-title mb-3" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_3.title")) + " "
                      )
                    ]),
                    _c("p", { staticClass: "card-content" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_3.item_3.content")) + " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("a", {
                      staticClass: "card-content-title mb-3",
                      attrs: {
                        target: "_blank",
                        href: "https://www.thsrc.com.tw/"
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_3.item_4.title"))
                      }
                    })
                  ])
                ])
              ])
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "container-fluid mrt wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-subway" }),
                _vm._v(" " + _vm._s(_vm.$t("section_4.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h1", {
                    staticClass: "bus-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_4.item_3.title"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://www.krtc.com.tw/"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_4.item_2.title"))
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container-fluid bus wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-bus" }),
                _vm._v(" " + _vm._s(_vm.$t("section_5.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("h1", { staticClass: "bus-text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_5.item_3.title")) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_5.item_1.title")) + " ")
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_5.item_1.content"))
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("h1", { staticClass: "bus-text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_5.item_7.title")) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_5.item_4.title")) + " ")
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_5.item_4.content"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_5.item_5.title")) + " ")
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_5.item_5.content"))
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://ibus.tbkc.gov.tw/ibus/"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_5.item_6.title"))
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container-fluid light-rail wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card mb-last-block" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-subway" }),
                _vm._v(" " + _vm._s(_vm.$t("section_6.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("h1", {
                    staticClass: "bus-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_6.item_5.title"))
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_6.item_1.title")) + " ")
                  ]),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_6.item_1.content"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("p", { staticClass: "card-content-title mb-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t("section_6.item_2.title")) + " ")
                  ]),
                  _c("p", { staticClass: "card-content" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("section_6.item_2.content")) + " "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://www.krtc.com.tw/KLRT/"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_6.item_4.title"))
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container-fluid city-bike wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "row traffic-image-only-card mb-last-block" },
          [
            _c("b-card", { staticClass: "traffic-content-card" }, [
              _c("h1", { staticClass: "mb-0" }, [
                _c("i", { staticClass: "fas fa-biking" }),
                _vm._v(" " + _vm._s(_vm.$t("section_7.title")) + " ")
              ]),
              _c("hr", { attrs: { size: "1" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("a", {
                    staticClass: "card-content-title mb-3",
                    attrs: {
                      target: "_blank",
                      href: "https://www.youbike.com.tw/region/kcg/"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_7.item_1.title"))
                    }
                  }),
                  _c("p", {
                    staticClass: "card-content",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("section_7.item_1.content"))
                    }
                  })
                ]),
                _c("div", { staticClass: "col-lg-6" })
              ])
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }