var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-color" },
    [
      _c("Navbar"),
      _c("Header", {
        attrs: {
          category: "visitors",
          bannerTitle: _vm.bannerTitle,
          breadCrumb: _vm.borrowSpaceCrumb
        }
      }),
      _c(
        "div",
        { staticClass: "solutions-area section-width ptb-100" },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "section-title" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_1_2")))]),
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
                )
              ])
            ]),
            _c("div", { staticClass: "row justify-content-center mt-5" }, [
              _c("div", { staticClass: "category-navigate" }, [
                _c("ul", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".parking")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_1.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".drive-a-car")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_2.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".high-speed-rail")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_3.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".mrt")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_4.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".bus")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_5.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".light-rail")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_6.title")))
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goAnchor(".city-bike")
                        }
                      }
                    },
                    [
                      _c("li", { staticClass: "btn btn-primary btn-lg" }, [
                        _vm._v(_vm._s(_vm.$t("section_7.title")))
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c("TrafficBody")
        ],
        1
      ),
      _c("FooterTop"),
      _c("FooterBottom")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }