<template>
    <div>
        <div class="google-map"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.230638147001!2d120.299306!3d22.607861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e03786848ef81%3A0x758740dc67754dd1!2zODA26auY6ZuE5biC5YmN6Y6u5Y2A5oiQ5Yqf5LqM6LevMznomZ_pq5jpm4TlsZXopr3ppKg!5e0!3m2!1szh-TW!2stw!4v1701920833456!5m2!1szh-TW!2stw" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
        </div>
</template>
<style>
    .google-map {
        width: 100%;
        height: 500px;
        margin:  0 auto;
    }
</style>
<script>
    import GoogleMapsApiLoader from 'google-maps-api-loader'

    export default {
        props: {
            mapConfig: Object,
            apiKey: String,
        },

        data() {
            return {
                google: null,
                map: null
            }
        },
        async mounted() {
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: this.apiKey
            })
            this.google = googleMapApi
            this.initializeMap()
            this.initMarker()
        },
        methods: {
            initializeMap() {
                const mapContainer = this.$refs.googleMap
                this.map = new this.google.maps.Map(
                    mapContainer, this.mapConfig
                )
            },
            initMarker() {
                const { Marker } = this.google.maps
                this.marker = new Marker({
                    position: this.mapConfig.center,
                    map: this.map,
                    title: "高雄展覽館!"
                })
            }
        }
    }
</script>