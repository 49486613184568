var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "google-map" }, [
        _c("iframe", {
          staticStyle: { border: "0" },
          attrs: {
            src:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.230638147001!2d120.299306!3d22.607861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e03786848ef81%3A0x758740dc67754dd1!2zODA26auY6ZuE5biC5YmN6Y6u5Y2A5oiQ5Yqf5LqM6LevMznomZ_pq5jpm4TlsZXopr3ppKg!5e0!3m2!1szh-TW!2stw!4v1701920833456!5m2!1szh-TW!2stw",
            width: "100%",
            height: "100%",
            allowfullscreen: "",
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }